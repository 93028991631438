@import 'src/styles/variables';

.wrapper {
	@include mobileL {
		margin-top: 28px;
	}
	.intro {
		position: relative;
		display: block;
		font-size: 30px;
		font-family: $gilroy;
		font-weight: 500;
		margin-bottom: $padding;
		@include tablet {
			font-size: 25px;
			font-weight: 600;
		}
	}
	.texte {
		font-family: $graphik;
		font-size: 14px;
		line-height: 28px;
		width: 102%;
		//text-align: justify;
		@include laptop {
			font-size: 18px;
		}
		@include tablet {
			width: 100%;
		}

		@include mobileL {
			width: 100%;
			line-height: 24px;
		}
	}
}
