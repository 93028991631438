@import "src/styles/variables";

.wrapper {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ad043b;
	transition: 0.3s opacity;

	&:not(.isOpen) {
		opacity: 0;
		pointer-events: none;
	}
}

.list {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 960px;
	margin: auto;
}

.link {
	display: block;
	position: relative;
	padding: 10px $padding;
	transition-duration: 0.2s;
	transition-property: opacity, transform;

	@include tablet {
		padding: 10px 50px;
	}

	@include mobileL {
		padding: 10px $padding;
	}

	@include tabletL {
		padding: 10px $padding;
	}

	.wrapper:not(.isOpen) & {
		opacity: 0;
		transform: translateX(-50px);
	}
}

.big {
	display: block;

	font-size: calc(3vw + 16px);
	font-weight: 900;
	font-family: $gilroy;
	text-transform: uppercase;
	-webkit-text-stroke: 1px currentColor;
	color: #fff;

	transition-duration: 0.3s;
	transition-property: color, text-shadow, -webkit-text-stroke-color, transform;

	@include mouse {
		transform: translateY(20px);

		.link:hover & {
			color: white;
			transform: none;
			text-shadow: 1px 1px $grey, 1px -1px $grey, -1px -1px $grey, -1px 1px $grey;

			@supports (-webkit-text-stroke-color: #000) {
				-webkit-text-stroke-color: $grey;
				text-shadow: none;
			}
		}
	}
}

.small {
	display: block;

	font-size: calc(1.5vw + 12px);
	font-style: italic;
	font-family: $playfair;
	color: white;
	transition-duration: 0.3s;
	transition-property: opacity, transform;

	@include mouse {
		.link:not(:hover) & {
			opacity: 0;
			transform: translateY(-20px);
		}
	}
}

.mentionsLegalesLink {
	color: green;
	display: block;
	position: relative;
	padding: 10px $padding;
	transition-duration: 0.2s;
	transition-property: opacity, transform;

	@include tablet {
		padding: 10px 50px;
	}

	@include mobileL {
		padding: 10px $padding;
	}

	@include tabletL {
		padding: 10px $padding;
	}

	.wrapper:not(.isOpen) & {
		opacity: 0;
		transform: translateX(-50px);
	}
}

.mentionLegalesTitle {
	display: block;
	font-size: calc(1vw + 16px);
	font-weight: 900;
	font-family: $gilroy;
	text-transform: uppercase;
	-webkit-text-stroke: 1px currentColor;
	color: #fff;

	transition-duration: 0.3s;
	transition-property: color, text-shadow, -webkit-text-stroke-color, transform;

	@include mouse {
		transform: translateY(20px);

		.link:hover & {
			color: white;
			transform: none;
			text-shadow: 1px 1px $grey, 1px -1px $grey, -1px -1px $grey, -1px 1px $grey;

			@supports (-webkit-text-stroke-color: #000) {
				-webkit-text-stroke-color: $grey;
				text-shadow: none;
			}
		}
	}
}

.mentionLegalesSubtitle {
	color: black;
	display: none;
}
