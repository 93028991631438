@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 70px;
	height: 70px;

	z-index: 1;
	transition: 0.3s opacity;

	&.isHidden {
		opacity: 0;
		pointer-events: none;
	}
}

.line {
	width: 28px;
	height: 3px;
	margin: 2px 0;
	background-color: #ffffff;
	transform-origin: left;
	transition: 0.2s transform;

	&:first-child {
		.isOpen & {
			transform: translate(4px, -3px) rotate(45deg);
		}
	}

	&:nth-child(2) {
		transform: scaleX(0.7);

		.isOpen & {
			transform: scaleX(0);
		}
	}

	&:last-child {
		transform: scaleX(0.85);

		.isOpen & {
			transform: translate(4px, 3px) rotate(-45deg);
		}
	}
}
