@import 'src/styles/variables';

.page {
	position: relative;
	// max-width: cols(12, desktop);
	max-width: $maxWidth;
	margin: auto;
	z-index: 0;
	padding: 0 $padding * 2;
	@include laptop {
		margin: 0;
		padding: 0 28px;
	}
}

.hautPage {
	display: flex;
	position: relative;
	flex-direction: column;
	margin: auto;
	max-width: $maxWidth;
	// margin-bottom: $padding * 2;
	margin-top: 100px;
	@include enter($duration: 0.5s, $delay: 0.5s) {
		transform: translateY(40px);
	}
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}
	.imgDroite,
	.imgGauche {
		position: absolute;
		z-index: -1;
		// animation: fadeInUp ease-in-out;
		// animation-fill-mode: forwards;
		// opacity: 0;
		// animation-duration: 0.7s;
		// animation-delay: 0.9s;
	}
}

.partie1 {
	position: relative;
	max-width: $maxWidth;
	margin: auto;
	box-sizing: border-box;
	margin-top: $padding * 8;
	@include tablet {
		margin-top: $padding * 6;
	}
	@include mobileL {
		margin-top: $padding * 3;
	}
	.wrapperSection {
		display: flex;
		box-sizing: border-box;
		@include mobileL {
			flex-direction: column-reverse;
		}
	}
	.img {
		display: flex;
		flex-direction: column;
		// align-items: center;
		position: relative;
		max-width: cols(6, desktop);
		box-sizing: border-box;
		@include exit($duration: 0.2s) {
			transform: translateY(40px);
		}
		@include laptop {
			max-width: cols(6, tablet, 1);
		}
		@include tablet {
			height: 100%;
		}

		@include mobileL {
			max-width: cols(10, mobile);
		}
		@include mobile {
			max-width: 100%;
		}
		img {
			width: 100%;
		}
	}

	.text {
		position: relative;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		margin: auto 0;
		width: cols(6, desktop);
		padding-left: 40px;
		z-index: 3;
		@include exit($duration: 0.2s) {
			transform: translateY(40px);
		}
		@include laptop {
			width: cols(6, tablet);
			padding-left: 28px;
		}
		@include mobileL {
			margin-top: $padding;
			left: cols(2, mobile);
			width: cols(10, mobile, 1);
			margin-bottom: $padding * 2;
		}
		@include mobile {
			padding-left: 0px;
			left: 0;
			width: 100%;
		}
	}
	// .imgAnim,
	// .textAnim {
	// 	animation: fadeInUp ease-in-out;
	// 	animation-fill-mode: forwards;
	// 	opacity: 0;
	// 	animation-duration: 0.7s;
	// }
	.imgAnim {
		@include enter($duration: 0.4s, $delay: 0.6s) {
			transform: translateY(40px);
		}

		@include exit($duration: 0.2s) {
			transform: translateY(40px);
		}
		// animation-delay: 1.1s;
	}
	.textAnim {
		@include enter($duration: 0.3s, $delay: 0.7s) {
			transform: translateY(40px);
		}

		@include exit($duration: 0.2s) {
			transform: translateY(40px);
		}
		// animation-delay: 1.4s;
	}
	.statResponsive {
		position: relative;
		width: cols(6, tablet);
		margin: auto;
		@include mobileL {
			margin-top: $padding;
			width: cols(12, mobile);
		}
	}
}

.partie2 {
	position: relative;
	max-width: $maxWidth;
	margin: auto;
	// padding: 0 $padding * 2;
	box-sizing: border-box;
	margin-top: $padding * 8;
	@include tablet {
		margin-top: $padding * 6;
	}
	.wrapperSection {
		.wrapper {
			position: relative;
			display: flex;
			justify-content: flex-end;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			@include mobileL {
				justify-content: flex-end;
			}
			.img {
				display: flex;
				flex-direction: column;
				// align-items: center;
				position: relative;
				z-index: -1;
				width: cols(6, desktop);
				box-sizing: border-box;
				@include laptop {
					left: 0;
					width: cols(6, tablet);
				}
				@include mobileL {
					right: 0;
					width: cols(10, tablet);
				}
				@include mobile {
					width: 100%;
				}
				img {
					width: 100%;
				}
			}
		}
		.text {
			position: relative;
			display: flex;
			flex-direction: column;
			left: cols(1, desktop, 1);
			width: cols(4, desktop);
			box-sizing: border-box;
			margin: auto 0;
			// padding: 0 40px;
			margin-bottom: $padding * 2;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			@include laptop {
				left: 0;
				width: cols(6, tablet);
			}
			@include mobileL {
				width: cols(9, mobile);
			}
			@include mobile {
				width: 100%;
			}
		}
		.img2 {
			position: relative;
			left: cols(1, desktop, 1);
			width: cols(4, desktop);
			box-sizing: border-box;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			@include laptop {
				left: cols(2, tablet, 1);
				width: cols(4, tablet);
			}
			img {
				width: 100%;
			}
		}
		.wrapper2 {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			.vignette {
				position: relative;
				bottom: 0px;
				left: -20px;
				top: $padding * 2;
				width: 182px;
				z-index: 5;
				padding: 15px;
			}
		}
		.wrapper3 {
			position: absolute;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			img {
				position: relative;
				top: $padding * 4;
				width: cols(8, tablet);
				height: 100%;
				top: -100px;
			}
		}
	}
}

.partie3 {
	position: relative;
	max-width: $maxWidth;
	margin: auto;
	// padding: 0 $padding * 2;
	box-sizing: border-box;
	margin-top: $padding * 8;
	transition: transform 0.5s, opacity 0.5s;
	@include tablet {
		margin-top: $padding * 6;
	}
	@include mobileL {
		margin-top: $padding * 4;
	}
	.wrapperSection {
		position: relative;
		display: flex;

		box-sizing: border-box;
		@include mobileL {
			flex-direction: column;
		}
		.img3 {
			display: flex;
			flex-direction: column;
			// align-items: center;
			position: relative;
			width: cols(7, desktop);
			box-sizing: border-box;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			@include laptop {
				width: cols(9, desktop);
			}
			@include mobileL {
				top: 40px;
				width: cols(12, desktop);
			}
			@include mobile {
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
		.text {
			position: relative;
			display: flex;
			flex-direction: column;
			// justify-content: space-between;
			margin: auto 0;
			width: cols(5, desktop);
			padding: 0 40px;
			z-index: 3;
			@include exit($duration: 0.2s) {
				transform: translateY(40px);
			}
			@include laptop {
				top: 100px;
				padding: 0 28px;
				width: cols(4, desktop);
			}
			@include mobileL {
				top: 40px;
				left: cols(2, mobile);
				width: cols(11, mobile, 1);
				margin-bottom: $padding * 2;
				margin-top: $padding * 2;
			}
			@include mobile {
				padding: 0px;
				left: 0;
				width: 100%;
			}
			.titre {
				position: relative;
				top: -150px;
				width: 150%;
				@include laptop {
					top: -50px;
				}
				@include mobileL {
					width: 100%;
					top: 0px;
					margin-bottom: 0px;
				}
			}
		}
		.wrapper {
			justify-content: flex-start;
			padding: 60px 50px;
		}
	}
	.text3Desktop {
		position: relative;
		top: -150px;
	}
	.text3 {
		position: relative;
		top: $padding * 2;
		left: cols(6, desktop);
		width: cols(6, desktop);
	}
}

.deconstruct {
	opacity: 0;
	transform: translate(0, -100px);
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}
}

.saison {
	transition: transform 1s;
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}

	@include tablet {
		margin-top: -75px;
	}

	@include mobile {
		margin-top: 0;
	}
}

.center {
	top: 50vh;
	transform: translateY(-42.5vh) scale(0.5);
	@include mobileL {
		transform: translateY(-42.5vh);
	}
}

.end {
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}
}
