@import 'src/pages/pages';

.page {
	@include tablet {
		overflow-x: hidden;
	}

	.img2 {
		@include tablet {
			display: none;
		}
	}

	.quartierOrange {
		margin-left: -50px;
		margin-top: -15px;
		@include tablet {
			width: 55%;
			margin-left: -25px;
			margin-top: -21px;
		}
		@include mobileL {
			margin-left: -30px;
			margin-top: -18px;
			width: 60%;
		}
	}
	.feuille {
		position: absolute;
		height: auto;
		margin-top: 232px;
		margin-right: -80px;
		right: 0px;
		z-index: -1;
		@include laptop {
			margin-top: 202px;
		}

		@include tablet {
			width: 50%;
			margin-right: -55px;
			margin-top: 85px;
		}

		@include mobileL {
			width: 50%;
			margin-top: 70px;
			margin-right: -25px;
		}
	}
	.vignetteOrange {
		background-color: #8cd3ab;
		@include tablet {
			bottom: -200px;
		}
	}
	.vignetteRadis {
		background-color: #ffec99;
		@include tablet {
			bottom: -170px;
		}
		span {
			// width: 90%;
			font-size: 43px;
			font-weight: 700;
			padding: 10px 0;
			// text-transform: uppercase;
			@include laptop {
				width: 100%;
				font-size: 30px;
			}
			// margin-bottom: $padding;
		}

		@media (max-width: 425px) {
			margin-top: -100px;
			margin-bottom: -55px;
		}
	}
	.vignetteAgrumes {
		background-color: #b8e986;
		@include laptop {
			bottom: 0px;
		}
		@include tablet {
			bottom: -125px;
		}
		span {
			font-size: 43px;
			font-weight: 700;
			// margin-bottom: $padding;
			@include laptop {
				font-size: 30px;
			}
		}
	}
}
