@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-wrap: wrap;

	@include media('<tablet') {
		flex-wrap: nowrap;
		flex-direction: column;
	}
}

.saison {
	position: relative;
	flex: 50%;
	height: 50vh;

	@include media('<tablet') {
		min-height: 150px;
		height: auto;
	}
}

.title {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	height: 100%;

	font-size: 5vw;
	font-weight: 900;
	font-family: $gilroy;
	letter-spacing: 0.15em;
	text-transform: uppercase;

	z-index: 1;
	-webkit-text-stroke: 1px currentColor;
	transition-duration: 0.3s;
	transition-property: color, text-shadow, -webkit-text-stroke-color;

	&:not(:hover) {
		color: white;
		text-shadow: 1px 1px $grey, 1px -1px $grey, -1px -1px $grey, -1px 1px $grey;

		@supports (-webkit-text-stroke-color: #000) {
			-webkit-text-stroke-color: $grey;
			text-shadow: none;
		}
	}

	@include media('<tablet') {
		font-size: 12vw;
	}
}
