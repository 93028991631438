@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;

	max-width: $maxWidth;
	margin: auto;
	padding: 20px 0px;
	z-index: 100;

	@include laptop {
		margin: 0 auto;
		padding: 10px 30px 0 41px;
	}

	@include media('<tablet') {
		margin: 0 auto;
		padding: 4px 1px 0 17px;
	}
}

.logo {
	position: relative;
	width: 130px;
	height: 25px;
	z-index: 1;

	:global(.logo-text) {
		transition: 0.3s opacity;
	}

	&.hidden :global(.logo-text) {
		opacity: 0;
	}
}

.divHeadRight {
	display: flex;
	align-items: center;

	.svg {
		width: 34px;
		height: 34px;
		border: 1px solid #fff;
		border-radius: 50%;
		text-align: center;
		line-height: 34px;
		transition: 0.3s border;
	}

	.svgRed {
		width: 34px;
		height: 34px;
		border: 1px solid #e72a7e;
		border-radius: 50%;
		text-align: center;
		line-height: 34px;
		transition: 0.3s border;
		transition-delay: 0.5s;
	}

	.hidden {
		transition: 0.3s opacity;
		opacity: 0;
		pointer-events: none;
	}

	.red {
		fill: #e72a7e;
		transition: 0.3s fill;
		transition-delay: 0.5s;
	}

	.white {
		fill: #fff;
		transition: 0.3s fill;
	}
}
