@import 'src/styles/variables';

.wrapper {
	z-index: 10;
	margin-top: 150px;
	// animation: fadeInUp ease-in-out;
	// animation-fill-mode: forwards;
	// opacity: 0;
	// animation-duration: 0.7s;
	// animation-delay: 0.3s;
	@include enter($duration: 0.7s, $delay: 0.3s) {
		transform: translateY(40px);
	}

	@include exit() {
		transform: translateY(40px);
	}
	@include tablet {
		margin-top: 50px;
	}
	.title {
		color: #9a094a;
		font-family: $gilroy;
		font-weight: 900;
		margin: auto;
		font-size: 120px;
		line-height: 122px;
		text-align: center;
		text-transform: uppercase;
		@include laptopL {
			font-size: 100px;
			line-height: 102px;
		}

		@include laptopXL {
			font-size: 120px;
			line-height: 122px;
		}

		@include laptop {
			font-size: 70px;
			line-height: 72px;
		}

		@include mobileL {
			font-size: 35px;
			line-height: 40px;
		}
	}
}
