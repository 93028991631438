@import 'src/styles/variables';

.wrapper {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 281px;
	left: cols(2, desktop, 1);
	bottom: -150px;
	padding: $padding;
	@include laptop {
		width: 182px;
	}
	@include tablet {
		left: 50px;
	}
	@include mobile {
		margin: auto;
	}
	span {
		text-align: center;
		display: block;
		font-family: $gilroy;
		font-size: 70px;
		padding: 10px;
		font-weight: 900;
		@include laptop {
			font-size: 50px;
		}
	}
	p {
		text-align: center;
		padding: 10px;
		font-family: $graphik;
		font-size: 17px;
		font-weight: 400;
		line-height: 26px;
		@include laptop {
			padding: 0px;
			font-size: 14px;
		}
	}
}
.imgCorse {
	max-width: 40px;
}
