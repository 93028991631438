@import 'src/styles/variables';

.wrapper,
.clicked {
	position: relative;
	display: flex;
	justify-content: center;
}

.wrapper {
	circle {
		transition: cx 0.5s, cy 0.5s;
	}
	:hover {
		circle {
			cx: 1250;
			cy: -500;
		}
	}
}

.clicked {
	circle {
		display: none;
	}
	svg {
		path {
			stroke-dashoffset: 0;
			animation: disappear 1.5s linear forwards;
		}
	}
}
@keyframes disappear {
	to {
		stroke-dasharray: 1000;
		stroke-dashoffset: 1000;
	}
}
