@import 'src/styles/variables';

.wrapper {
	z-index: 10;
	// animation: fadeInUp ease-in-out;
	// animation-fill-mode: forwards;
	// opacity: 0;
	// animation-duration: 0.7s;
	// animation-delay: 0.6s;
	@include enter($duration: 0.3s, $delay: 0.4s) {
		transform: translateY(40px);
	}

	@include exit() {
		transform: translateY(40px);
	}
	.phrase {
		margin-top: 45px;
		font-size: 30px;
		text-align: center;
		font-style: italic;
		font-family: $playfair;
		font-style: italic;
		line-height: 45px;
		@include laptop {
			font-size: 28px;
			line-height: 40px;
		}
		@include mobileL {
			font-size: 18px;
			line-height: 26px;
		}
	}
}
