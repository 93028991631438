@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
}

.input {
	margin-bottom: 30px;
	padding: 15px 0;

	font-size: 16px;
	font-weight: 600;

	border: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.26);

	transition: 0.2s opacity;

	&::placeholder {
		font-size: 14px;
		font-weight: 400;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.textarea {
	height: 150px;
	resize: vertical;
}

.submit {
	margin: 40px auto;
	padding: 10px 20px;
	font-weight: 600;
	text-transform: uppercase;
	border: none;

	&::after {
		content: '';
		display: block;
		height: 1px;
		margin-top: 5px;
		background-color: currentColor;
		transition-duration: 0.2s;
		transition-property: color, transform;
	}

	&:hover:not(:disabled),
	&:focus {
		&::after {
			transform: scaleX(0.2);
		}
	}

	&:disabled {
		color: $grey;
	}
}

.error,
.success {
	margin-top: 20px;
	color: #944040;
	text-align: center;
}

.success {
	color: #3a7c57;
}
