@import 'src/pages/pages';

.page {
	@include tablet {
		overflow-x: hidden;
	}

	.img2 {
		@include tablet {
			display: none;
		}
	}

	.butternut {
		margin-left: -110px;
		margin-top: -160px;
		width: 63%;

		@include laptop {
			margin-top: -90px;
			margin-left: -80px;
		}

		@include tablet {
			margin-top: -80px;
			margin-left: -50px;
			width: 65%;
		}

		@include mobileL {
			width: 80%;
			margin-top: -55px;
			margin-left: -55px;
		}
	}

	.feuille {
		position: absolute;
		height: auto;
		margin-top: 285px;
		margin-right: -76px;
		right: 0px;
		z-index: -1;
		@include tablet {
			width: 38%;
			margin-top: 110px;
			margin-right: -35px;
		}
		@include mobileL {
			margin-left: -60px;
			width: 45%;
			margin-top: 77px;
		}
	}
	.vignetteFigue {
		background-color: #d0e594;
		@include tablet {
			bottom: -75px;
		}
	}
	.vignettePdt {
		background-color: #ffee9e;
		min-width: min-content;
		@include tablet {
			bottom: -170px;
		}
		span {
			// width: 90%;
			font-size: 43px;
			font-weight: 700;
			padding: 10px 0;
			// text-transform: uppercase;
			@include laptop {
				width: 100%;
				font-size: 30px;
			}
			// margin-bottom: $padding;
		}
	}
	.vignettePoire {
		background-color: #ff988a;
		@include laptop {
			bottom: 0px;
		}
		@include tablet {
			bottom: -125px;
		}
		span {
			font-size: 43px;
			font-weight: 700;
			// margin-bottom: $padding;
			@include laptop {
				font-size: 30px;
			}
		}
	}

	// .titrePoireG {
	// 	left: -138px;
	// }
}
