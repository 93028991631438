@import 'src/pages/pages';

.wrapperSection {
	.wrapper {
		.img {
			@include mobileL {
				width: 100% !important;
			}
		}
	}
}
.page {
	@include tablet {
		overflow-x: hidden;
	}

	.saison {
		@include tablet {
			margin-top: 0;
		}
	}
	.partie2 {
		margin-bottom: 300px;

		@include tablet {
			margin-bottom: 135px;
		}

		@include mobileL {
			margin-bottom: 80px;
		}
	}

	.aristote {
		font-size: 16px;
		font-weight: 600;
		margin: auto;
		margin-top: 20px;
		// animation: fadeInUp ease-in-out;
		// animation-fill-mode: forwards;
		// opacity: 0;
		// animation-duration: 0.5s;
		// animation-delay: 0.5s;

		@include mobileL {
			display: none;
		}
	}
	.graines {
		margin-left: -85px;
		margin-top: -10px;
		@include tablet {
			width: 65%;
			margin-top: -35px;
		}
		@include mobileL {
			margin-left: -80px;
			margin-top: -35px;
			width: 65%;
		}
	}
	.pousse {
		position: absolute;
		height: auto;
		margin-top: 13px;
		margin-right: -110px;
		right: -45px;
		z-index: -1;
		width: 60%;

		@include tablet {
			width: 50%;
			margin-right: 0px;
			right: -60px;
			width: 52%;
			margin-top: 95px;
		}
		@include mobileL {
			margin-right: 0px;
			right: -60px;
			width: 52%;
			margin-top: 95px;
		}
	}

	.vignettePdt {
		position: relative;
		top: -40px;
		display: flex;
		flex-direction: column-reverse;
		background-color: #fbd3a4;
		padding: 30px;
		span {
			font-size: 43px;
			font-weight: 700;
			line-height: 68px;
			padding: 0;
		}
		p {
			font-size: 17px;
			font-weight: 400;
			line-height: 34px;
			padding: 0;

			@include tablet {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
	.vignetteCamion {
		position: relative;
		top: 100px;
		display: flex;
		flex-direction: column-reverse;
		background-color: #b8e986;
		// padding: 30px;
		left: cols(2);

		@include mobile {
			top: -10px;
		}

		span {
			font-size: 43px;
			font-weight: 700;
			line-height: 68px;
			padding: 0;

			@include tablet {
				font-size: 30px;
			}
		}
		p {
			font-size: 17px;
			font-weight: 400;
			line-height: 34px;
			padding: 0;

			@include tablet {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
	.inverted {
		display: flex;
		flex-direction: column-reverse;
		transition: transform 0.5s, opacity 0.5s;
	}
	.partie1 {
		@include mobileL {
			margin-top: 40px;
		}
		.titre {
			position: relative;
			top: -150px;
			width: 130%;
			@include laptop {
				top: -50px;
			}
			@include mobileL {
				width: 100%;
				top: 0px;
			}
			@include laptopL {
				width: 142%;
			}
			@include laptopXL {
				width: 150%;
			}
		}
		.textEnd {
			position: relative;
			top: -150px;
			@include laptop {
				top: -50px;
			}
			@include mobileL {
				top: -10px;
			}
		}
		.text3 {
			@include mobileL {
				margin-bottom: -20px;
			}
		}

		.imgimgCarrottes {
			@include mobileL {
				width: 100%;
			}
		}

		.img3 {
			position: relative;
			@include mobileL {
				top: 20px;
			}
		}
	}
	.partie2 {
		@include tablet {
		}

		@include mobileL {
			margin-top: 20px;
			margin-bottom: 25px;
		}

		.img {
			@include tablet {
				width: 100% !important;
			}
		}

		.icon {
			left: inherit;
			@include tablet {
				width: cols(12);
			}
			@include tabletL {
				width: cols(7);
			}
			@include laptopL {
				width: cols(5);
			}
		}

		.textCamion {
			@include tablet {
				top: -100px;
			}
			@include mobileL {
				top: 0;
			}
			@include tabletL {
				margin-top: -345px;
				margin-bottom: 100px;
			}
		}

		.iconPartie2 {
			width: 110px;

			@include mobile {
				width: 80px;
			}
		}

		.dflex {
			display: flex;
			justify-content: space-between;
		}

		.services {
			font-weight: 700;
			text-align: center;
			text-transform: uppercase;
			margin: 50px 0;

			@include mobile {
				margin-bottom: 30px;
			}
		}

		.subTitle {
			font-weight: 700;
			text-align: center;
			text-transform: uppercase;
			margin: 20px 0;
			height: 30px;
		}

		.part {
			text-align: center;
		}
	}
	.end {
		position: relative;
		text-align: center;
		margin-bottom: $padding * 10;
		transition: transform 0.5s, opacity 0.5s;

		@include laptop {
			margin-top: $padding * 2;
		}

		@include mobileL {
			margin-top: 80px;
			margin-bottom: 100px;
		}

		p {
			font-style: italic;
			font-family: $playfair;
			font-size: 30px;
			line-height: 45px;

			@include mobileL {
				font-size: 16px;
				line-height: 24px;
			}
		}
		a {
			position: relative;
			top: $padding * 2;
			font-size: 14px;
			font-weight: 700;
			padding: 15px;
			text-transform: uppercase;
			border: 1px solid black;

			@include mobileL {
				padding: 15px 35px;
			}
		}
	}
}

.text {
	@include laptop {
		font-size: 18px;
	}
	@include mobileL {
		left: 0 !important;
		width: 97% !important;
		padding-left: 0 !important;
	}
}

.mobileP {
	font-family: $graphik;
	font-size: 14px;
	line-height: 28px;
	width: 102%;
	margin-top: -30px;
	@include mobileL {
		width: 100%;
		line-height: 24px;
	}
}

.divImgAgriculture {
	display: flex;
	margin-bottom: 25px;

	.imgAB {
		width: 110px;
		margin: auto 40px auto 0;
	}

	.imgIDF {
		width: 74px;
	}
}
