@import 'variables';
@import 'fonts';

html {
	color: $text;
	font-size: 14px;
	font-family: $graphik;
	background-color: white;
	overscroll-behavior: none;
	//overflow: hidden;
}

body {
	height: 100%;
	overflow-x: hidden;
	overflow-y: overlay; // Si on l'active, ça gêne au niveau du header, surtout le Burger
	overscroll-behavior: none; // sert à désactiver le pull-to-refresh
	cursor: none;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background: lighten($grey, 10);
	&:hover {
		background: $grey;
	}
}

// Hide Opera video button
body ~ div[style] {
	display: none !important;
}
