@import 'src/styles/variables';

.wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-bottom: $padding * 3;
	@include tablet {
		top: 20px;
	}
	@include mobileL {
		margin-bottom: $padding;
	}
	.titre {
		font-family: $gilroy;
		font-size: 90px;
		font-weight: 900;
		line-height: 90px;
		text-transform: uppercase;
		@include laptop {
			font-size: 56px;
			line-height: 56px;
		}
		@include tablet {
			font-size: 45px;
			line-height: 45px;
		}
		@include mobileL {
			font-size: 32px;
			line-height: 32px;
		}
		@include laptopL {
			font-size: 72px;
			line-height: 72px;
		}
		@include laptopXL {
			font-size: 90px;
			line-height: 90px;
		}
	}
}
.gauche {
	left: -105px;
	@include laptop {
		left: -75px;
		width: 310px;
	}

	@include tablet {
		width: 100%;
	}

	@include mobileL {
		text-align: left;
		left: 0px;
	}

	@include tabletL {
		width: 400px;
	}

	@include laptopL {
		width: 530px;
	}

	@include laptopXL {
		width: 630px;
	}
}
.droite {
	left: 60px;
	text-align: right;
	@include laptop {
		left: 45px;
		width: 310px;
	}

	@include tablet {
		width: 100%;
	}

	@include mobileL {
		text-align: left;
		left: 0px;
	}
	@include tabletL {
		width: 400px;
	}

	@include laptopL {
		width: 530px;
	}

	@include laptopXL {
		width: 630px;
	}
}
