@mixin fontFace($name, $weight, $italic: false) {
	$url: '/fonts/' + $name + '-' + $weight + if($italic, '-italic', '') + '.woff';
	@font-face {
		font-family: $name;
		src: url($url+'2') format('woff2'), url($url) format('woff');
		font-weight: $weight;
		@if ($italic) {
			font-style: italic;
		}
		font-display: swap;
	}
}

// Fonts
@include fontFace(graphik, 400);
@include fontFace(graphik, 600);
@include fontFace(gilroy, 700);
@include fontFace(gilroy, 900);
@include fontFace(playfair-display, 400, true);
