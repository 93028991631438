@import 'src/styles/variables';

/*--------------------------------------------------------------
ROUE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Branches
- Points
- Mois
- Titre
- Imgs
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	Global
--------------------------------------------------------------*/
.roue_wrapper {
	display: flex;
	position: relative;
	align-items: center;
	top: -($headerH + $gap * 2);
	padding: ($headerH + $gap * 2) ($headerH/2 + $gap) 0;
	height: 1100px;
	width: 1100px;
	margin: auto;
	margin-top: 100px;
	@include enter($duration: 0.5s, $delay: 0.5s) {
		transform: translateY(40px);
	}
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}

	// margin-bottom: -($headerH + $gap * 2);
	box-sizing: border-box;
	@include laptop {
		height: 850px;
		width: 850px;
	}
	@include tablet {
		height: 600px;
		width: 600px;
		top: -100px;
	}
	@include mobileL {
		height: 400px;
		width: 400px;
		top: 0;
		padding: 0;
	}
	@include mobile {
		height: 280px;
		width: 280px;
	}
}
.roue {
	position: relative;
	height: 65%;
	width: 65%;
	margin: auto;
	// border: 2px solid grey;
	border-radius: 50%;
	backface-visibility: hidden;
	box-sizing: border-box;
}

/*--------------------------------------------------------------
	Branches
--------------------------------------------------------------*/
.roue label {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -40px;
	width: 80px;
	height: 25%;
	transform-origin: 50% 200%;
	// cursor: pointer;
	z-index: 3;
	@for $i from 1 through 12 {
		&:nth-child(#{$i}) {
			transform: rotate(#{$i * 30-30}deg);
		}
	}
	@include mobileL {
		width: 40px;
		margin-left: -20px;
	}

	@include tablet {
		height: 27%;
		top: -9px;
	}

	&:before {
		@include pseudo;
		position: absolute;
		top: -80px;
		left: -40px;
		right: -40px;
		bottom: 0;
		@include mobileL {
			top: -50px;
			left: -10px;
			right: -10px;
		}
	}
}

/*--------------------------------------------------------------
-	Points
--------------------------------------------------------------*/
.roue {
	span {
		display: block;
		position: absolute;
		margin-left: -8px;
		top: -8px;
		left: 50%;
		width: 16px;
		height: 16px;
		background: #202334;
		border-radius: 50%;
		transition: 0.2s;
		transform: translate(0%, 0%);
		@include mobileL {
			margin-left: -5px;
			top: -5px;
			width: 8px;
			height: 8px;
		}
		&:before {
			@include pseudo;
			@include abs;
			background-color: #fff;
			border-radius: 50%;
			z-index: 1;
			transform: scale(0);
			transition: 0.2s;
		}
	}
	label:hover span {
		transform: scale(1.5);
	}
	input {
		display: none;
	}
	input:checked + span {
		transform: scale(2.5);
		&:before {
			transform: scale(0.4);
		}
	}
}

/*--------------------------------------------------------------
-	Mois
--------------------------------------------------------------*/
.roue {
	.mois {
		position: relative;
		padding: 0;
		left: -1px;
		top: -25px;
		@include sans;
		line-height: 1;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 12px;
		user-select: none;
		transform: rotate(-90deg);
		text-align: right;
		@include mobileL {
			font-size: 8px;
		}
	}
	label:nth-child(n + 7) .mois {
		text-align: left;
		transform: rotate(90deg);
	}
}

/*--------------------------------------------------------------
-	Titre
--------------------------------------------------------------*/
.roue div {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	width: 60%;
	@include dalafloda;
	font-size: 3.7em;
	color: #fff;
	text-align: center;
	z-index: 2;
	transform: translateY(-50%);
	@include laptopL {
		font-size: 2.5em;
	}
	@include mobileL {
		font-size: 1.6em;
	}
}

/*--------------------------------------------------------------
-	Imgs
--------------------------------------------------------------*/
.roue {
	figure {
		position: absolute;
		top: 5%;
		left: 5%;
		right: 5%;
		bottom: 5%;
		overflow: hidden;
		border-radius: 300px;
		z-index: 1;
		background: #000;
		img {
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: 0.6s;
			backface-visibility: hidden;
			&.on {
				opacity: 1;
			}
		}
		.on {
			opacity: 1;
		}
		p {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: auto;
			font-weight: 700;
			width: 60%;
			font-family: dalafloda, georgia, serif;
			font-size: 60px;
			color: #fff;
			text-align: center;
			z-index: 2;
			opacity: 0;
			transform: translateY(-50%);
			@include tablet {
				font-size: 45px;
			}
			@include mobileL {
				font-size: 40px;
			}
			@include mobile {
				font-size: 30px;
			}
		}
		.textOn {
			opacity: 1;

			@include mobileL {
				opacity: 0;
			}
		}
	}
}

/*--------------------------------------------------------------
-	Texte
--------------------------------------------------------------*/
.texte {
	max-width: 800px;
	margin: auto;
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}
	.saison {
		font-size: 90px;
		font-weight: 700;
		text-align: center;
		margin: auto;
		margin-bottom: 50px;
		color: #dba8bc;
		// font-family: 'Playfair Display';
		@include mobileL {
			font-size: 50px;
		}
	}
	.wrapper {
		display: flex;
		justify-content: space-between;
		@include mobileL {
			flex-direction: column;
		}
		.section {
			padding: $padding;
			width: 50%;
			@include mobileL {
				padding: $paddingM;
				width: 100%;
			}
			.titre {
				font-weight: 700;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 20px;
			}
			.francais,
			.monde {
				.part {
					margin-bottom: 20px;
					padding: 0;
					.title {
						margin-bottom: 5px;
						padding: 0;
						font-size: 14px;
						font-weight: 600;
						text-transform: uppercase;
					}
					.content {
						font-size: 12px;
						line-height: 20px;
					}
				}
			}
			.vedette {
				position: relative;
				padding: 40px 20px;
				text-align: center;
				border: 4px solid #9a094a;
				margin: 50px 0;
				.part:last-child {
					margin: 0;
				}
				.part {
					margin-bottom: 20px;
					.title {
						margin-bottom: 10px;
						font-weight: 700;
						text-align: center;
						text-transform: uppercase;
					}
					.content {
						font-size: 12px;
						line-height: 20px;
					}
					::before {
						content: '';
						display: block;
						position: absolute;
						top: 6px;
						left: 6px;
						right: 6px;
						bottom: 6px;
						border: 1px solid #9a094a;
					}
				}
			}
			.fin {
				.title {
					margin-bottom: 10px;
					font-weight: 700;
					text-align: center;
					text-transform: uppercase;
				}
				.content {
					font-size: 12px;
					line-height: 20px;
				}
			}
		}
	}
}
