// Enter Animation
@mixin enter($duration: 0.3s, $delay: 0s) {
	$id: enter + unique_id();

	:global(.page-enter) & {
		animation: $id $duration $delay ease-out backwards;

		@keyframes #{$id} {
			0% {
				opacity: 0;
				@content;
			}
		}
	}
}

// Exit Animation
@mixin exit($duration: 0.3s, $delay: 0s) {
	$id: exit + unique_id();

	:global(.page-exit) & {
		animation: $id $duration $delay ease-in forwards;

		@keyframes #{$id} {
			100% {
				opacity: 0;
				@content;
			}
		}
	}
}

// Hover
@mixin mouse {
	@media (hover: hover) and (pointer: fine) {
		@content;
	}
	@include ie {
		@content;
	}
}

// Edge only
@mixin edge {
	@supports (-ms-ime-align: auto) {
		@content;
	}
}

// IE only
@mixin ie {
	@media all and (-ms-high-contrast: none) {
		@content;
	}
}
