@import 'src/styles/variables';

.wrapper {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 101;
	background-color: #ad043b;
	animation: fade 0.8s forwards;
	animation-delay: 2s;

	.introLogo {
		position: absolute;
		width: 5%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		stroke-dasharray: 120;
		stroke-dashoffset: 120;
		stroke: white;
		animation: stroke 2s forwards;
		animation-delay: 0.2s;
		fill: transparent;

		@include tablet {
			width: 15%;
		}
	}
}

@keyframes stroke {
	30% {
	}

	55% {
		stroke-dashoffset: 10;
		opacity: 1;
	}

	86% {
		stroke-dashoffset: 0;
	}

	95% {
		fill: transparent;
		opacity: 0;
	}

	100% {
		fill: white;
		stroke-dashoffset: 0;
		opacity: 0;
	}
}

// a migrer en gsap ? ou changer la couleur a chaque changement de saison
@keyframes fade {
	20% {
		opacity: 1;
		background: #ff7c1c;
	}

	99% {
		background: #ff7c1c;
		opacity: 0;
		user-select: none;
		pointer-events: none;
	}

	100% {
		background: #ff7c1c;
		opacity: 0;
		user-select: none;
		pointer-events: none;
	}
}
