@import 'src/styles/variables';

.canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	transition: 0.5s transform;
	z-index: -1;

	@include media('>=laptop') {
		&.zoomedOut {
			transform: scale(0.8);
			transition-delay: 0.3s;
		}
	}
}
