@import 'src/styles/variables';

$pointSize: 5px;
$circleSize: 40px;
$lineWidth: 10px;
$lineGap: 30px;
$transitionDuration: 0.3s;

.point,
.circle,
.lines {
	position: fixed;
	pointer-events: none;
	z-index: 100;

	@media (pointer: coarse) {
		display: none;
	}
}

.point {
	left: $pointSize / -2;
	top: $pointSize / -2;
	width: $pointSize;
	height: $pointSize;

	border-radius: 50%;
	background: #000;
	transition-property: background-color;
	transition-duration: $transitionDuration;

	&.hover {
		background-color: #fff;
	}
}

.circle {
	top: $circleSize / -2;
	left: $circleSize / -2;
	width: $circleSize;
	height: $circleSize;

	&::before {
		content: '';
		display: block;
		height: 100%;
		border: 1px solid #000;
		border-radius: 50%;

		transition-duration: $transitionDuration;
		transition-property: border-color, background-color, opacity, transform;
		transition-timing-function: $bounce;
	}

	&.hover {
		&::before {
			opacity: 0.4;
			background-color: #000;
			transform: scale(1.5);
		}
	}

	&.drag {
		&::before {
			opacity: 0.4;
			// border-color: #fff;
			// background-color: #fff;
			// transform: scale(2);
		}
	}
}

.lines {
	top: 0;
	left: -31px;
	width: 62px;

	&::before {
		content: '';
		position: absolute;
		top: -4px;
		left: 0px;
		height: 0px;
		width: 0px;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-right: 4px solid rgba(5, 5, 5, 0.9);
		transition: $transitionDuration transform;
	}

	&::after {
		content: '';
		position: absolute;
		top: -4px;
		right: 0px;
		height: 0px;
		width: 0px;
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 4px solid rgba(5, 5, 5, 0.9);

		transition: $transitionDuration transform;
	}

	&:not(.drag) {
		&::before,
		&::after {
			transform: scaleX(0);
		}
	}
}
