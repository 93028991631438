@import 'src/pages/pages';

.page {
	.img2 {
		@include tablet {
			display: none;
		}
	}

	@include tablet {
		overflow-x: hidden;
	}
	.hautPage {
		margin-top: 115px;
	}
	.artichaut {
		margin-left: -105px;
		margin-top: -75px;
		@include tablet {
			width: 60%;
			margin-top: -50px;
		}
		@include mobileL {
			margin-left: -90px;
			margin-top: -40px;
			width: 80%;
		}
	}
	.fleur {
		position: absolute;
		height: auto;
		right: 0px;
		z-index: -1;
		margin-top: 280px;
		margin-right: -125px;
		@include tablet {
			width: 35%;
			margin-top: 120px;
			margin-right: -45px;
		}

		@include mobileL {
			width: 50%;
			margin-top: 85px;
			margin-right: -60px;
		}
	}
	.vignetteAsperge {
		background-color: #faf39d;
		@include tablet {
			bottom: -200px;
		}
	}
	.vignetteRadis {
		background-color: #ffd4e7;
		@include tablet {
			bottom: -170px;
		}
		span {
			width: 90%;
			font-size: 43px;
			font-weight: 700;
			text-transform: uppercase;
			@include laptop {
				width: 100%;
				font-size: 30px;
			}
			// margin-bottom: $padding;
		}
	}
	.vignetteFraise {
		background-color: #b8e986;
		@include laptop {
			bottom: 0px;
		}
		@include tablet {
			bottom: -125px;
		}
		span {
			font-size: 43px;
			font-weight: 700;
			// margin-bottom: $padding;
			@include laptop {
				font-size: 30px;
			}
		}
	}
}
