@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 150px;
	height: 150px;
	margin: auto;

	user-select: none;
	-webkit-user-drag: none;
	z-index: 3;

	@include enter($delay: 0.2s) {
		transform: translateY(-40px);
	}

	@include exit() {
		transform: translateY(-40px);
	}
}

$lineHeight: 40px;
$barHeight: 10px;

.line {
	width: 1px;
	height: $lineHeight;
	background-color: #ddd;

	&::before {
		content: '';
		display: block;
		height: $barHeight;
		background-color: #000;
		animation: slide 1.5s infinite;
	}
}

@keyframes slide {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(#{$lineHeight - $barHeight});
	}
}

.text {
	margin-top: 20px;
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #ffffff;
}
