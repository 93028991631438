@import 'src/styles/variables';

$slideWidthUntil768: 100;
$slideWidthFrom769: 45;

:export {
	slideWidthUntil768: $slideWidthUntil768;
	slideWidthFrom769: $slideWidthFrom769;
}

.wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 2;
	user-select: none;
}

.saisons {
	display: flex;
	height: 100%;
	align-items: center;
}

.saison {
	flex: 0 0 $slideWidthUntil768 * 1%;

	@include media('>=769px') {
		flex: 0 0 $slideWidthFrom769 * 1%;
	}
}

.title {
	display: flex;
	justify-content: center;
	align-items: center;
	display: none; // hide
	height: 100%;

	font-size: 5vw;
	font-weight: 900;
	font-family: $gilroy;
	letter-spacing: 0.15em;
	text-transform: uppercase;

	z-index: 1;
	pointer-events: none;
	-webkit-text-stroke: 1px currentColor;

	transition-duration: 0.3s;
	transition-property: color, text-shadow, -webkit-text-stroke-color, transform;

	span {
		transition: 0.3s transform;

		@include enter {
			transform: translateY(-40px);
		}

		@include exit {
			transform: translateY(-40px);
		}

		&:last-child {
			letter-spacing: 0;
		}

		@for $i from 1 through 9 {
			&:nth-child(#{$i}) {
				animation-delay: $i * 30ms;

				:global(.page-enter) & {
					animation-delay: $i * 30ms + 300ms;
				}
			}
		}
	}

	@include media('>=laptop') {
		.wrapper:not(.dragging) &.active {
			transform: scale(1.2);

			span {
				transform: scale(0.8);

				@include enter($duration: 0.2s) {
					transform: scale(0.8) translateY(-60px);
				}

				@include exit($duration: 0.2s) {
					transform: scale(0.8) translateY(-60px);
				}

				@for $i from 1 through 9 {
					&:nth-child(#{$i}) {
						animation-delay: $i * 30ms;

						:global(.page-enter) & {
							animation-delay: $i * 30ms + 300ms;
						}
					}
				}
			}
		}
	}

	&:not(.active) {
		color: white;
		text-shadow: 1px 1px $grey, 1px -1px $grey, -1px -1px $grey, -1px 1px $grey;

		@supports (-webkit-text-stroke-color: #000) {
			-webkit-text-stroke-color: $grey;
			text-shadow: none;
		}

		@include media('>=laptop') {
			.wrapper:not(.dragging) & {
				transform: scale(0.8);

				span {
					transform: scale(1.2);
				}
			}
		}
	}

	@include media('<laptop') {
		font-size: 7vw;
	}

	@include media('<tablet') {
		font-size: 10vw;
	}
}

.prev,
.next {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 1;
	transition: 0.2s opacity;

	&::before {
		content: '';
		display: block;
		width: 15px;
		height: 15px;
		margin-left: 30px;
		margin-right: 20px;
		border: 1px solid #fff;
		border-top: none;
		border-right: none;
		transform: rotate(45deg);

		@include media('>tablet') {
			width: 35px;
			height: 35px;
			margin-left: 40px;
			margin-right: 30px;
		}
	}

	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}

.prev {
	left: 0;

	@include enter($delay: 0.3s) {
		transform: translateY(-20px);
	}

	@include exit {
		transform: translateY(-20px);
	}
}

.next {
	right: 0;
	transform: rotate(180deg);

	@include enter($delay: 0.3s) {
		transform: translateY(-20px) rotate(180deg);
	}

	@include exit {
		transform: translateY(-20px) rotate(180deg);
	}
}
