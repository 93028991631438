@import 'node_modules/include-media/dist/include-media';
@import 'mixins';

/*--------------------------------------------------------------
-	Colors
--------------------------------------------------------------*/

// Main colors
$red: #d0021b;
$black: #000;
$rose: #c61168;

// Greys
$grey: #999999;
$lightGrey: #eff0f2;

/*--------------------------------------------------------------
-	Variables
--------------------------------------------------------------*/

$headerH: 150px;
$gap: 20px;
$padding: 40px;
$paddingM: 28px;

$text: $black;
$gilroy: gilroy, sans-serif;
$background: $lightGrey;
$line: $lightGrey;

// Radius
$radius: 2px;

// Margin / Padding
$padding: 20px;

// Fonts
$bodoni: 'bodoni-72', sans-serif;
$graphik: 'graphik', sans-serif;
$gilroy: 'gilroy', sans-serif;
$playfair: 'playfair-display', serif;

/*--------------------------------------------------------------
-	Animation
--------------------------------------------------------------*/

@keyframes fadeInUp {
	0% {
		transform: translate(0, 100px);
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}

/*--------------------------------------------------------------
-	Transitions
--------------------------------------------------------------*/
$duration: 0.2s;
$quint: cubic-bezier(0.7, 0, 0.3, 1);
$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutQuad: cubic-bezier(0.46, 0.03, 0.52, 0.96);

/*--------------------------------------------------------------
-	mixin
--------------------------------------------------------------*/

@mixin full($margin: 0, $not: 0) {
	@if ($not != top) {
		top: 0;
	}
	@if ($not != left) {
		left: 0;
	}
	@if ($not != right) {
		right: 0;
	}
	@if ($not != bottom) {
		bottom: 0;
	}
	@if ($margin == 1) {
		margin: auto;
	}
}
@mixin abs($margin: 0, $not: 0) {
	position: absolute;
	@include full($margin, $not);
}
@mixin fix($margin: 0, $not: 0) {
	position: fixed;
	@include full($margin, $not);
}

@mixin pseudo {
	content: '';
	display: block;
}
@mixin trait {
	&:after {
		@include pseudo;
		display: block;
		margin: 15px auto 30px;
		width: 20px;
		border: 1px solid currentColor;
		@include laptop {
			margin: 15px auto;
			width: 15px;
		}
		@include tablet {
			margin: 10px auto;
			width: 10px;
		}
	}
}

/*--------------------------------------------------------------
	Typo
--------------------------------------------------------------*/
@mixin sans {
	font-family: opensans, arial;
	font-weight: 600;
}

@mixin dalafloda {
	font-family: dalafloda, georgia, serif;
}

@mixin title {
	font-size: 4em;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	@include sans;
	@include laptopL {
		font-size: 3.7vw;
	}
	@include laptop {
		font-size: 3em;
	}
	@include mobileL {
		font-size: 2.15em;
	}
}
@mixin subtitle {
	font-size: 2.3em;
	@include dalafloda;
	@include laptop {
		font-size: 2em;
	}
	@include mobileL {
		font-size: 1.4em;
	}
}

/*--------------------------------------------------------------
-	Medias queries
--------------------------------------------------------------*/
@mixin mobile {
	@media (max-width: 475px) {
		@content;
	}
}
@mixin mobileL {
	@media (max-width: 525px) {
		@content;
	}
}
@mixin tablet {
	@media (max-width: 838px) {
		@content;
	}
}
@mixin tabletL {
	@media (min-width: 838px) {
		@content;
	}
}
@mixin laptop {
	@media (max-width: 1024px) {
		@content;
	}
}
@mixin laptopL {
	@media (min-width: 1025px) {
		@content;
	}
}

@mixin laptopXL {
	@media (min-width: 1440px) {
		@content;
	}
}

// Breakpoints
$breakpoints: (
	mobile: 425px,
	tablet: 768px,
	laptop: 1024px,
	desktop: 1440px,
	xxl: 2560px,
);

// Sizes
$pad: 30px;
$maxWidth: 1250px;
$cols: (
	mobile: 12,
	tablet: 12,
	desktop: 12,
);
$colPad: (
	mobile: 20px,
	tablet: 20px,
	desktop: 40px,
);
// Attention ! Pas de line break sinon Post CSS plante
@function cols($nbr, $size: mobile, $addGap: false, $fixed: false) {
	@if not map-has-key($cols, $size) or not map-has-key($colPad, $size) {
		@error "Invalid size: `#{$size}` in cols() function";
	}
	$totalCols: map-get($cols, $size);
	$padding: map-get($colPad, $size);
	$totalPadding: ($totalCols - 1) * $padding;
	$paddingSum: ($nbr - 1) * $padding;
	$unit: if($size == 'desktop', '%', 'vw');
	@if ($addGap) {
		$paddingSum: $paddingSum + $padding * $addGap;
	}
	@if ($size != desktop) {
		$totalPadding: $totalPadding + $pad * 2;
	}
	@if ($fixed) {
		$wrapperSize: $maxWidth - $totalPadding;
		$colWidth: $wrapperSize / ($totalCols / $nbr);
		@return $colWidth + $paddingSum;
	} @else {
		$wrapperSize: unquote('(100#{$unit} - #{$totalPadding})');
		$colWidth: $wrapperSize / #{$totalCols / $nbr};
		@return calc(#{$colWidth} + #{$paddingSum});
	}
}
