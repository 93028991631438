@import 'src/styles/variables';

.wrapper {
	position: relative;
	text-align: center;
	margin-top: $padding * 12;
	margin-bottom: $padding * 16;
	@include exit($duration: 0.2s) {
		transform: translateY(40px);
	}
	@include laptop {
		margin-top: $padding * 6;
	}
	@include mobileL {
		margin-top: $padding * 12;
		margin-bottom: 110px;
	}
	p {
		font-size: 30px;
		font-style: italic;
		font-family: $playfair;
		line-height: 45px;

		@include mobileL {
			font-size: 18px;
			line-height: 35px;
		}
	}
	a {
		position: relative;
		top: $padding * 2;
		font-size: 14px;
		font-weight: 700;
		padding: 15px;
		text-transform: uppercase;
		border: 1px solid black;
	}
}
