@import 'src/pages/pages';

.titleTomate {
	@include tabletL {
		width: 430px;
	}

	@include laptopL {
		width: 560px;
	}

	@include laptopXL {
		width: 708px;
	}
}
.page {
	@include tablet {
		overflow-x: hidden;
	}

	.img2 {
		@include tablet {
			display: none;
		}
	}
	.cerise {
		margin-left: -105px;
		margin-top: -220px;
		@include laptop {
			width: 70%;
			margin-left: -65px;
		}

		@include tablet {
			width: 60%;
			margin-top: -182px;
			margin-left: -30px;
		}

		@include mobileL {
			width: 66%;
			margin-top: -52px;
			margin-left: -35px;
		}
	}
	.feuille {
		position: absolute;
		height: auto;
		margin-top: 185px;
		margin-right: -43px;
		right: 0px;
		z-index: -1;
		@include laptop {
			width: 40%;
		}

		@include tablet {
			margin-top: 65px;
			margin-right: -20px;
		}

		@include mobileL {
			width: 40%;
			margin-right: -20px;
			margin-top: 70px;
		}
	}
	.vignetteTomate {
		background-color: #fff;
		bottom: -70px;
		@include tablet {
			bottom: -75px;
		}
	}
	.vignetteMelon {
		background-color: #d1e0c2;
		min-width: min-content;
		@include tablet {
			bottom: -170px;
		}
		@include mobileL {
			left: 0 !important;
			margin: 0;
		}
		span {
			// width: 90%;
			font-size: 43px;
			font-weight: 700;
			padding: 10px 0;
			// text-transform: uppercase;
			@include laptop {
				width: 100%;
				font-size: 27px;
			}
			// margin-bottom: $padding;
		}
	}
	.vignetteRaisin {
		background-color: #ffee9e;
		@include laptop {
			bottom: 0px;
		}
		@include tablet {
			bottom: -200px;
		}
		span {
			font-size: 43px;
			font-weight: 700;
			// margin-bottom: $padding;
			@include laptop {
				font-size: 30px;
			}
		}
	}
}
