@import 'src/styles/variables';

.wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
	max-width: 1100px;
	margin: auto;
	margin-top: 160px;
	font-family: $graphik;
	@include enter($duration: 0.4s, $delay: 0.5s) {
		transform: translateY(40px);
	}
	@include exit($duration: 0.3s) {
		transform: translateY(40px);
	}

	@include laptop {
		max-width: 600px;
	}
	@include tablet {
		max-width: 400px;
		flex-direction: column;
	}
	@include mobileL {
		max-width: 300px;
		flex-direction: column;
	}
}

.title {
	margin-bottom: 30px;
	font-size: 30px;
	font-weight: 600;
}

.left {
	@include media('>tablet') {
		width: cols(5);
	}
}

.right {
	margin-bottom: 50px;

	@include media('>tablet') {
		width: cols(2);
	}

	p {
		line-height: 20px;
	}

	.numero {
		margin-top: $padding;
	}
}
